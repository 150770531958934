import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../elements/Team";
import Accordion01 from "../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Slider2 from "../component/slider/SliderTwo"
import about from "../assets/images/about/dylan.jpg";
import about2 from "../assets/images/about/about-3.png";

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--18",
    category: "",
    title: "Vcrypt Financial.",
    description:
      "High performance algo trading software",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--17",
    category: "",
    title: "Development.",
    description:
      "Next generation trading dev tools",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--19",
    category: "",
    title: "Proven Results.",
    description:
      "Record shattering returns for our clients",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];
    var namesItemTwo = [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ];

    const PostList = BlogContent.slice(0, 3);

    return (
      <Fragment>
        <Helmet pageTitle="Business" />
        <Header />
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider2 className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="2"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider2>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Who we are</h2>
                  <p>
                  Vcrypt Financial is an algorithm based hedge fund that can be
                  applied to foreign exchange, futures, and options markets on a global
                  scale.  With our highly adaptable program, we use high frequency
                  trading in order to bring investor's maximum profit.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="3"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={about}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Working Process</h2>
                    <p>
                    Software backed success.
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See how it works
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start About Area  }
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">
                      Who are we? What do we do? What do we stand for?
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Lorem ipsum dolor sit.</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>Lorem ipsum dolor sit.</h4>
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img
                    //className="w-100"
                    //src={about2}
                    //alt="About Images"
                  />
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="ZOoVOfieAF8"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        { End About Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    Here are our founders.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team column="col-lg-3 col-md-6 col-sm-6 col-12" />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Business;
