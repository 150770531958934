import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";



const SlideList = [
    {
      textPosition: "text-left",
      bgImage: "bg_image--18",
      category: "",
      title: "Vcrypt Financial.",
      description:
      "High performance algo trading software",
      buttonText: "Contact Us",
      buttonLink: "/contact",
    },
    {
      textPosition: "text-left",
      bgImage: "bg_image--17",
      category: "",
      title: "Development.",
      description:
      "Next generation trading dev tools",
      buttonText: "Contact Us",
      buttonLink: "/contact",
    },
    {
      textPosition: "text-left",
      bgImage: "bg_image--19",
      category: "",
      title: "Proven Results.",
      description:
      "Record shattering returns for our clients",
      buttonText: "Contact Us",
      buttonLink: "/contact",
    },
  ];
  
class SliderOne extends Component {
    constructor() {
        super();
        this.slider_ref = React.createRef(null);
    }

    componentDidMount() {
        this.slider_interval = setInterval(() => {
            this.slider_ref.current.slickNext();
        }, 5000);
    }

  render() {
    return (
        <div className="slider-activation">
            <Slider ref={this.slider_ref} className="rn-slick-dot dot-light" {...slideSlick}>
                {SlideList.map((value , index) => (
                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
  }
}
export default SliderOne;