import React, { Component } from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import team1 from "../assets/images/team/team-01.jpg";
import team2 from "../assets/images/team/team-02.jpg";
import team3 from "../assets/images/team/team-03.jpg";
import team6 from "../assets/images/team/team-06.jpg";
import team5 from "../assets/images/team/team-05.jpg";
import team12 from "../assets/images/team/team-12.jpg";

let TeamContent = [
  {
    images: team1,
    title: "Ethan Marreel",
    designation: "Chief Executive Officer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/ethan-marreel-0090ab1b6/",
      },
    ],
  },
  {
    images: team2,
    title: "Ethan Clinick",
    designation: "President of Analytics and Alogrithms",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/ethanclinick/",
      },
    ],
  },
  {
    images: team3,
    title: "Luke Frugia",
    designation: "President of Core Software",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/luke-frugia-baa81a26a/",
      },
    ],
  },
  {
    images: team6,
    title: "Alexander Roller",
    designation: "Chief Financial Officer",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/alexander-roller/",
      },
    ],
  },
];

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {TeamContent.map((value, i) => (
          <div className={`${column}`} key={i}>
            <div className="team">
              <div className="thumbnail">
                <img src={value.images} alt="Blog Images" />
              </div>
              <div className="content">
                <h4 className="title">{value.title}</h4>
                <p className="designation">{value.designation}</p>
              </div>
              <ul className="social-icon">
                {value.socialNetwork.map((social, index) => (
                  <li key={index}>
                    <a href={`${social.url}`}>{social.icon}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
