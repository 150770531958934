import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/Vcrypt laptop and desktop3.jpg";
import findingImg from "../assets/images/about/finding-us-01.png";
import teamImg1 from "../assets/images/team/team-01.jpg";
import teamImg2 from "../assets/images/team/team-02.jpg";
import teamImg3 from "../assets/images/team/team-03.jpg";
import teamImg4 from "../assets/images/team/team-06.jpg";

class About extends Component {
  render() {
    let title = "About",
      description =
        "Our mission at Vcrypt financial; to revolutionize the landscape of equities trading through innovative technology and a fresh perspective.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title" style={{color: "#fff"}}>About</h2>
                                    <p>More about our software</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="black-box" >

            </div>
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100 hide-mobile"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title" style={{color:"#fff"}}>{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title" style={{color:"#fff"}}>How it works</h3>
                          <p>
                          Our company has developed a strategic scalping software that is applicable to any market.  Specially designed, it will capitalize on every bull and every bear, bringing our investors maximum profitability at all times. 
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title" style={{color:"#fff"}}>Our goal</h3>
                          <p>
                          Our goal is to research and develop trading software at
                          the bleeding-edge of technology.  Software that can help any trader,
                          regardless of their background or experience, to navigate the
                          complexities of the stock market with confidence and efficiency.

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area }
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {End CounterUp Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    Here are our founders.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start Single Team  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg1}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Ethan Marreel</h4>
                    <p className="designation">Chief Executive Officer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/ethan-marreel-0090ab1b6/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
              {/* Start Single Team  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg2}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Ethan Clinick</h4>
                    <p className="designation">President of Analytics & Algo Trading</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/ethanclinick/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
              {/* Start Single Team  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg3}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Luke Frugia</h4>
                    <p className="designation">President of Core Software</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/luke-frugia-baa81a26a/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
              {/* Start Single Team  */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg4}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Alexander Roller</h4>
                    <p className="designation">Chief Financial Officer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.linkedin.com/in/alexander-roller/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}


        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
