import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [result, showResult] = useState(false);

  useEffect(() => {
    // Initialize EmailJS with the public key
    emailjs.init('B0VhCvzw0uFPrzLZE'); 
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_oi8q4n9', 
        'template_iwgmbrm', 
        e.target, 
        'B0VhCvzw0uFPrzLZE' 
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true);
        }, 
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();

    setTimeout(() => {
      showResult(false);
    }, 5000);
  };

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input 
          type="text"
          name="fullname"
          placeholder="Your Name"
          required
        />
      </div>

      <div className="rn-form-group">
        <input 
          type="email"
          name="email"
          placeholder="Your Email"
          required
        />
      </div>

      <div className="rn-form-group">
        <input 
          type="text"
          name="phone"
          placeholder="Phone Number"
          required
        />
      </div>

      <div className="rn-form-group">
        <input 
          type="text"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>

      <div className="rn-form-group">
        <textarea 
          name="message"
          placeholder="Your Message"
          required
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
          Submit Now
        </button>
      </div> 

      <div className="rn-form-group">
        {result ? <Result /> : null}
      </div> 
    </form>
  );
};

const Result = () => {
  return <p>Your message has been successfully sent. We will contact you soon.</p>;
};

export default ContactForm;
